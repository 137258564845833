
import Vector from './images/Vector.svg'
import Vector1 from './images/Vector1.svg'
import Vector3 from './images/Vector3.svg'
import Vector4 from './images/Vector4.svg'
const CardContent = [
    {
       id:1,
       title: " 1991",
       button:"Established on Year",
       image:Vector
    },
    {
        id:2,
        title: "  1000+",
        button:"Projects Delivered",
        image:Vector1
    },
    {
        id:3,
        title: "  100+",
        button:"Customized Products ",
        image:Vector3
    },
    {
        id:4,
        title: "950+",
        button:"Satisfied Customers ",
        image:Vector4
    }
  ];
  
  export default CardContent;
  