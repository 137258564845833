import React from "react";
import Footer from "./footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Banner from "./bannerimage";
import Chairman from "../images/chairman.svg";
import Employees from "../images/employees.svg";
import BannerContent from "../bannercontent";
import SmBackhome from "../images/SmBackhome.svg"; 
import UnionBack from "../images/unionBack.svg";
import  AboutLast from "../images/aboutlast.svg";
import  ProBar  from './progressbar';
import CardContent from '../cardcontent';
import CardPaln from './card';


function createCard(CardContent) {
  return (
    <CardPaln  class="plainCard" class2="padding-top white-text text-center "
      key={CardContent.id}
      title={CardContent.title}
      button={CardContent.button}
      Image= {CardContent.image}
   
    />
  );
}



function About() {
  return (
    <div >
      <Banner   alt={BannerContent[0].name}  imgurl={SmBackhome}  heading={BannerContent[0].heading} colorHeading={BannerContent[0].colorHeading} heading2={BannerContent[0].heading2}/>
    
   
      <Container  >
      <br/>
      <br/>
      <br/>
      <Row className="margin-zero padd-up-down-abt">
      <Col sm="12" md="6" className="d-none d-sm-none d-md-block d-lg-block d-xl-block  col">
      <img src={Chairman} className="responsive" alt=""/>
      </Col>
     
      <Col sm="12" md="6" >
    
      <div className="  padd-up-down-no">
          <h1 className="par"><span style={{color: "#146697"}}>Chairman's Message</span></h1>
          <p className="para" >Great things are not done by impulse, but by a series of good steps come together. MMR Mining Equipments today, is the series of great steps that incepted in 1992. We used to dream a lot and we had the courage to undertake any challenges that may come in the way of realizing those dreams. It was Determination, dedication and hard work that took us to the present position</p>  
        </div>
     
      </Col>
      <Col sm="12" md="6" className="d-block d-sm-block d-md-none d-lg-none d-xl-none ">
      <img src={Chairman} className="responsive" alt=""/>
      </Col>
      </Row>
      </Container>
      <Container  >
      <Row className="margin-zero">
      <Col  sm="12" md="6">
     
      
      <div className="padd-up-down">
          <h1 className="par"><span style={{color: "#146697"}}>Stronger Team means Stronger Future</span></h1>
          <p className="para" >MMR Mining Equipments is a manufacturer of high quality Rock Drilling Equipments & Accessories. One stop solution for all your mining, drilling, coring & construction needs Built on state-of-art technology, MMR's range of products have propelled many a client’s mining, construction and quarry industries to the higher drilling efficiencies and quality results.</p>  
        </div>
    
      </Col>
       <Col sm="12" md="6">
       <img src={Employees} className="responsive" alt=""/>
       </Col>
      </Row>
      </Container>
      <div className="margin-top-10">
      <section style={{backgroundImage: `url(${UnionBack})`, backgroundSize:'cover', width:'100%',}}  >
      <Container className="padd-up-down">
      <div >
      <h2 className=" white-text papad">Uncompromised Quality Of <br/> Execution</h2><br/> 
      </div>
      <Row className="margin-zero">
      {CardContent.map(createCard)}
      </Row>
    </Container>
    </section>
    </div>
    <section>
    <Container>
    <Row className=" margin-zero padd-up-down-abt" >
      <Col md="6" sm="12">
     <img src={AboutLast} alt=""/>
      </Col>
      <Col md="6" sm="12">
      <h5 class="para-heading relative">Experienced to handle</h5><br/>
        <ProBar idTypes="probar1"/>
      <h5 class="para-heading relative">Expectations that retain</h5><br/>
        <ProBar idTypes="probar2"/>
        <h5 class="para-heading relative">Execution that's flawless</h5><br/>
        <ProBar idTypes="probar3"/>
        <h5 class="para-heading relative">Efficient Service</h5><br/>
        <ProBar idTypes="probar4"/>
      </Col>
      </Row>
      </Container>
    </section>
    <Footer/>
    </div>
  );
}

export default About;
