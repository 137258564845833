import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import Logo from "../images/logo_1.svg";
import Container from "react-bootstrap/Container";
import  Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
function Header() {
    return(
      <div>

     <Col md={{ span: 4, offset: 8 }}>
      <div id="google_translate_element"></div>
      </Col>
      
<Navbar collapseOnSelect expand="lg" bg="white" variant="light">
<Container>
  <Navbar.Brand href="/"><img src={Logo} alt="logo" /></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav" >
 <span className="mr-auto"></span>
    <Nav  >
      <Nav.Link  href="/About" className="four" >About Us</Nav.Link>
      <Nav.Link href="/Portfolio" className="four">Portfolio</Nav.Link>
      <Nav.Link  href="/productpage" className="four">Products</Nav.Link>
      <Nav.Link  href="/accessoriespage" className="four">Accessories</Nav.Link>
      <Nav.Link href="/contact"><Button>Contact</Button></Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>







</div>
    );
}
export default Header;