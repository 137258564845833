import React from "react";
import First from "../images/First.svg";
import Second from "../images/Second.svg";


function Twoimage() {
    return(

    <div className="relative ">
    <img src={Second}  alt="" className="first-image box-shadow" />
    <img src={First}  className="absolute second-two box-shadow" alt="" />
  </div>

        );
    }
    export default Twoimage;