import React from 'react';
import  Col from "react-bootstrap/Col";
import  Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container"
import HeadingPara from "./heading";
import HeadparaContent from "../headingpara";
import Second from "../images/Second.svg";
import Footer from "./footer";
import Product from './product';
import CardPaln from './card';
import CardContent from '../cardcontent';
import ImageTextbox from "./imagetextbox";
import {Link} from "react-router-dom";
import Person from "../images/person.svg";
import Background1 from "../images/background1.svg";
import Button from "react-bootstrap/Button";
import fetch from "isomorphic-fetch";
import UnionBack from '../images/unionBack.svg';
import ImageContent from "../imagestext";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab';



function CreateProduct(products) {
      return (
        <Product class="coloredCard " class2="padding-top" class3="padd-up-down-10"
          key={products.id}
          title={products.name}
          text={products.description.substring(0, 50)}
         Image= {`https://app.mmrminings.com${products.image1.url}`}
         targetpage={`/productsingle/${products.id}`}

        />
      );
    }
    function CreateProductBlast(productsblast) {
      return (
        <Product class="coloredCard " class2="padding-top" class3="padd-up-down-10"
          key={productsblast.id}
          title={productsblast.name}
          text={productsblast.description.substring(0, 50)}
         Image= {`https://app.mmrminings.com${productsblast.image1.url}`}
         targetpage={`/productsingle/${productsblast.id}`}

        />
      );
    }

function createCard(CardContent) {
  return (
    <CardPaln  class="plainCard" class2="padding-top white-text"
      key={CardContent.id}
      title={CardContent.title}
      button={CardContent.button}
      Image= {CardContent.image}
    />
  );
}



    

class ProductPage extends React.Component {
  constructor(){
    super()
    this.state={
      products:[],
      productsblast:[],
      productswater:[]
    }
   
  }
  
  componentWillMount(){
    fetch('https://app.mmrminings.com/products').then((response)=> {
      if(response.status >= 400){
        throw new Error("page not found ");
      }
      return response.json();
    }).then((products)=> {
      this.setState({products:products});
    })
    fetch('https://app.mmrminings.com/products?categories.categoryname_contains=Blast%20hole%20drilling%20rigs').then((response)=> {
      if(response.status >= 400){
        throw new Error("page not found ");
      }
      return response.json();
    }).then((productsblast)=> {
    this.setState({productsblast:productsblast});
    })
    fetch('https://app.mmrminings.com/products?categories.categoryname_contains=Water%20well%20drilling%20rigs').then((response)=> {
      if(response.status >= 400){
        throw new Error("page not found ");
      }
      return response.json();
    }).then((productswater)=> {
    this.setState({productswater:productswater});
    })
    }

 render(){
    return (
   
      <div>
        <section style={{backgroundImage: `url(${Background1})`, backgroundSize:'cover'}} className="relative" >
      <Container className=" padd-up-down-per">
 <Row className="margin-zero">
 <Col sm="12" md="6" >
 <div className="padd-up-down">
<h3 className="yellow-text"> World Class Products</h3>
<br/>
<h1 className="blue"> Customizable to suit Customer's needs</h1>
</div>
</Col>
<Col sm="12" md="6" >
<img src={Person} alt="" className=" prdu-truck"  />    
</Col>
</Row>
</Container>
</section>
<section>
<Container className="d-none d-sm-none d-md-block d-lg-block d-xl-block padd-up-down" >
       <Row className="margin-zero">
         <Col xs="12" md="6" sm="12"  >
         <div>
        
        <img src={Second} className=" box-shadow  proc-img" alt=""/>
   
      </div>
         </Col>
         <Col xs="12" md="6" sm="12">
         <Container>
         <h5 style={{color: "#146697"}} className="para-heading relative ">Leading Product</h5><br/>
         <h3 style={{color: "#146697"}}> Wagon Drill MMR-110</h3>
         <p className="para"> Wagon Drill MMR-110 , capable of drilling 102-115 mm dia to a depth of 36mts . Used in open cast mines, quarries, civil works for drilling and blasting purpose .pneumatic drifter can be mounted to carry out drifter drilling .MMR WAGON DRILL -110 IS SPECIALLY designed for accurate drilling mounted on rugged box frame , fixed on wheels for easy shifting from hole to hole and able to drill holes in both vertical and horizontal positions to suit in INDIAN conditions.</p>
         <Link to="/productsingle/11" ><Button >know-more</Button></Link>
         </Container>
          </Col>
       </Row>
      </Container>
      <Container className="  padd-up-down d-block d-sm-block d-md-none d-lg-none d-xl-none" >
       <Row className="margin-zero">
  
         <Col xs="12" md="6" sm="12">
         <Container>
         <h5 style={{color: "#146697"}} className="para-heading relative ">Leading Product</h5><br/>
         <h3 style={{color: "#146697"}}>Wagon Drill MMR-110</h3>
         <p className="para"> Wagon Drill MMR-110 , capable of drilling 102-115 mm dia to a depth of 36mts . Used in open cast mines, quarries, civil works for drilling and blasting purpose .pneumatic drifter can be mounted to carry out drifter drilling .MMR WAGON DRILL -110 IS SPECIALLY designed for accurate drilling mounted on rugged box frame , fixed on wheels for easy shifting from hole to hole and able to drill holes in both vertical and horizontal positions to suit in INDIAN conditions.</p>
        <Link to="/productsingle/11" ><Button >know-more</Button></Link>
         </Container>
          </Col>
          <Col xs="12" md="6" sm="12"  className="padding-20" >
          <div>
        
        <img src={Second} className=" box-shadow proc-img " alt=""/>
   
      </div>
         </Col>
       </Row>
      </Container>
</section>

  <Container>
  <center><h3 style={{color: "#146697"}}>Our Products</h3></center>
  <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
  <Tab eventKey="all" title="All">
  <Row className=" margin-zero">
      {this.state.products.map(CreateProduct)}
      </Row>
   
  </Tab>
  <Tab eventKey="Blast" title="Blast hole drilling rigs">
  <Row className=" margin-zero">
  {this.state.productsblast.map(CreateProductBlast)}
      </Row>
  </Tab>
  <Tab eventKey="Water" title="Water well drilling rigs" >
  <Row className=" margin-zero">
  {this.state.productswater.map(CreateProduct)}

      </Row>
  </Tab>
  <Tab eventKey="contact" title="Customize Rigs" >
<center>
  <br/><br/>
  <div>
<h3>To Customize your rigs </h3>
</div>
<br/><br/>
<div>

<Link to="/Contact" ><Button >Contact-Us</Button></Link>
</div>
<br/><br/>
  </center>
  </Tab>
</Tabs>
     
</Container>
   <div >
      <section style={{backgroundImage: `url(${UnionBack})`, backgroundSize:'cover', width:'100%',}}  >
      <Container className="padd-up-down" >
      <div >
      <h2 className=" white-text papad">Uncompromised Quality Of <br/> Execution</h2><br/> 
      </div>
      <Row className="margin-zero">
      {CardContent.map(createCard)}
      </Row>
    </Container>
    </section>
    </div>
    
    <Container className="padd-up-down">
    <Row className="margin-zero">
          <Col sm="12" md="4">
          <HeadingPara class="padding-10" heading={HeadparaContent[0].heading} headingTwo={HeadparaContent[0].heading2}/>       
          </Col>
          <Col sm="12" md="4">
          <ImageTextbox   blue="Adopting Technologies in your mining requirements." image={ImageContent[7].image} heading={HeadparaContent[1].heading}  Para={HeadparaContent[7].Para}/><br/><br/>
        </Col>
        <Col sm="12" md="4">
        <ImageTextbox   blue="Spend smart for your next mining project" image={ImageContent[8].image} heading={HeadparaContent[2].heading} Para={HeadparaContent[8].Para}/><br/><br/>
        </Col>
        </Row>
        <br/>
        <br/>
    </Container>

<Footer/>
      </div>

);
}
}

export default ProductPage;