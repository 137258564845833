import React from 'react';
import Container from "react-bootstrap/Container"
import  Col from "react-bootstrap/Col";
import  Row from "react-bootstrap/Row";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Footer from "./footer";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button';
import Background1 from "../images/background1.svg";
import {Link} from "react-router-dom";

import fetch from "isomorphic-fetch";

function ProDetails(ProductDetails){
  return(

    <tr>
    <td>{ProductDetails.Name}</td>
    <td>{ProductDetails.details}</td>

    </tr>

  );
}
function ProSpeci(ProductSpecification){
  return(

    <tr>
    <td>{ProductSpecification.name}</td>
    <td>{ProductSpecification.description}</td>

    </tr>

  );
}


class ProductSingle extends React.Component {
constructor(){
     super()
     this.state={
           SingleProduct:[],
           ImageOne:'',
           ImageTwo:'',
           ImageThree:'',
           ProductDetails:[],
           ProductSpecification:[]
          
     } 
}
componentDidMount(){
      fetch(`https://app.mmrminings.com/products/${this.props.match.params.postid}`).then((response)=> {
            if(response.status >= 400){
              throw new Error("page not found ");
            }
            return response.json();
          }).then((product)=> {
            this.setState({SingleProduct: product});
            this.setState({ImageOne: product.image1.url});
            this.setState({ImageTwo: product.image2.url});
            this.setState({ImageThree: product.image3.url});
            this.setState({ProductDetails: product.Productdetail});
            this.setState({ProductSpecification: product.TechnicalSpecification});
          });

          }

render(){
  const {id, name, description, attribute1, attribute2, attribute3, attribute4, attribute5, attribute6,attribute7,attribute8 } = this.state.SingleProduct;

return (
   
      <div>
       <section style={{backgroundImage: `url(${Background1})`}} className="relative" >
<Container className="padd-up-down">
<Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row className="margin-zero">
    <Col sm={5} productid={id}>
 
      <Tab.Content>
        <Tab.Pane eventKey="first">
        <img src={`https://app.mmrminings.com${this.state.ImageOne}`} width="100%" alt=""/>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
        <img src={`https://app.mmrminings.com${this.state.ImageTwo}`} width="100%" alt=""/>
        </Tab.Pane>
        <Tab.Pane eventKey="third">
        <img src={`https://app.mmrminings.com${this.state.ImageThree}`} width="100%" alt=""/>
        </Tab.Pane>
       
      </Tab.Content>
      <Nav variant="pills" className="flex-column">
      <Row className="margin-zero">
      <Col>
        <Nav.Item>
          <Nav.Link eventKey="first"><img src={`https://app.mmrminings.com${this.state.ImageOne}`} width="100%" alt=""/></Nav.Link>
        </Nav.Item>
        </Col>
        <Col>
        <Nav.Item>
          <Nav.Link eventKey="second"><img src={`https://app.mmrminings.com${this.state.ImageTwo}`} width="100%" alt=""/></Nav.Link>
        </Nav.Item>
        </Col>
        <Col>
        <Nav.Item>
          <Nav.Link eventKey="third"><img src={`https://app.mmrminings.com${this.state.ImageThree}`}  width="100%" alt=""/></Nav.Link>
        </Nav.Item>
        </Col>
        <Col>
      
        </Col>
        </Row>
      </Nav>
 
    </Col>
    <Col sm={6}>
    <Container>
         <h5 style={{color: "#146697"}} className="para-heading relative ">{name}</h5>
         <p class="para">{description}</p>
         <Link to="/Contact" ><Button >Contact-Us</Button></Link>
         </Container>
    </Col>
  </Row>
</Tab.Container>
</Container>
</section>
<Container className="padd-up-down">
<center><br/>
<h3 style={{color: "#146697"}} >Product Features</h3>
<br/>
</center>
<Row className="margin-zero proback">
<Col xs="12" md="6">
<ul className="single-attr">
  <li>{attribute1}</li>
  <li>{attribute2}</li>
  <li>{attribute3}</li>
  <li>{attribute4}</li>
  <li>{attribute5}</li>
  <li>{attribute6}</li>
  <li>{attribute7}</li>
  <li>{attribute8}</li>
</ul>
</Col>
<Col xs="12" md="6">
<div >
       <img src={`https://app.mmrminings.com${this.state.ImageOne}`} width="100%" alt=""/>
       
    </div>
    </Col>
  

  </Row>
</Container>
<Container>
<center>
<br/>
<h3 style={{color: "#146697"}}> Product Details</h3>
<br/>
<Col xs="12" md="6">
<Table responsive striped bordered hover> 
  <thead>
    <tr>
     
    <th>Products</th>
    <th>details</th>
     
    </tr>
  </thead>
  <tbody>
{this.state.ProductDetails.map(ProDetails)}
</tbody>
</Table>
</Col>
</center>
</Container>
<Container>
<center>
<br/>
<h3 style={{color: "#146697"}} >Product Specifications</h3>
<br/>

<Col xs="12" md="6">
<Table striped bordered hover responsive>
  <thead>
    <tr>
     
    <th>Products</th>
    <th>details</th>
     
    </tr>
  </thead>
  <tbody>
{this.state.ProductSpecification.map(ProSpeci)}
</tbody>
</Table>

</Col>
</center>
<br/>
<center>

<Link to="/Contact" ><Button >Contact-Us</Button></Link>
</center>
<br/>
</Container>
<Footer/>
      </div>

);
}
}


export default ProductSingle;