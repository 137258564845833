import React from "react";
import emailjs from "emailjs-com";
import Footer from "./footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import UnionBack from "../images/unionBack.svg";


function sendEmail(e){
  e.preventDefault();

  emailjs.sendForm('service_9p5fb1i', 'template_gvh8c3c', e.target, 'user_GCEHhtP0DuRjMksB3YGAk')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset()
}


function Contact() {
  return (
    <div >
  <section style={{backgroundImage: `url(${UnionBack})`, backgroundSize:'cover', width:'100%',}}  >
  <br/>
     <Container>
   
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">

    <Col >
      <Nav variant="pills" className="flex-column">
      <Row className="margin-zero">
      <Col xs="6">
        <Nav.Item>
          <Nav.Link eventKey="first"><h3 className="white">SAY HELLO</h3></Nav.Link>
        </Nav.Item>
        </Col>
        <Col xs="6">
        <Nav.Item>
          <Nav.Link eventKey="second"><h3 className="white">CHAT WITH US</h3></Nav.Link>
        </Nav.Item>
        </Col>
        </Row>
      </Nav>
    </Col>
 
    <Col >
      <Tab.Content>
      <br/>
        <Tab.Pane eventKey="first">
        <div className="padd-up-down white-back"> 
        <center>
  
<div id="mc_embed_signup">
<form className="contact-form" onSubmit={sendEmail}>

<Row className="margin-zero">
<Col>

<div >
	<label> Name <span className="asterisk">*</span></label><br/>
	<input type="text" name="NAME" required="required" />
</div>
<div ><br/><br/>
	<label >Email Address  <span className="asterisk">*</span>
</label><br/>
	<input type="email" name="EMAIL" required="required"/>
</div> <br/><br/>
<div className="mc-field-group">
	<label >Product Enquiry  <span class="asterisk">*</span>
</label><br/>
	<select name="Enquiry" required="required">
	<option value=""></option>
	<option value="Drilling Equipment's">Drilling Equipment's</option>
<option value="Mining Equipment's">Mining Equipment's</option>
<option value="Drilling or Mining Accessories">Drilling or Mining Accessories</option>

	</select>
</div>
</Col>
<Col>
<div class="mc-field-group">
	<label >Your Message  <span className="asterisk">*</span>
</label><br/>

  <textarea  name="message"  className="textame">

</textarea>
</div>
<br/>
    </Col>
    </Row>
    <br/><br/>
    <input type="submit" value="Send" class="btn btn-lg btn-primary yellow"/> 
</form>
</div>
 

</center>
</div>

        </Tab.Pane>
        <Tab.Pane eventKey="second">
        <div className="padd-up-down white-back"> 
        <center>
        <a href="https://api.whatsapp.com/send?phone=+917075768588&amp;text=Hi. I have a requirement. Let's discuss.." className="watsapp"  target="_blank" rel="noopener noreferrer"><i class="fa fa-whatsapp my-float"></i></a>
        </center>
        </div>
        </Tab.Pane>
      </Tab.Content>
    </Col>
  
</Tab.Container>
<br/>
        
     </Container>

</section>
   <Row className="margin-zero">  
   <Col xs="12">
       <section id="googleMap">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237.87455732705257!2d78.61632068549933!3d17.46005310106982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9d4a14c65227%3A0x50dd8cf923f954ad!2sMMR%20MINING%20EQUIPMENTS!5e0!3m2!1sen!2sin!4v1605764928507!5m2!1sen!2sin" title="mmrmap" width="100%" height="500" frameborder="0"  allowfullscreen></iframe>
    </section>
    </Col>
    </Row>

    <Footer/>
    </div>
  );
}

export default  Contact;
