import React from "react";

function HeadingPara(props) {
    return(
        <div className={props.class}>
          <h1 className="par">{props.heading}<span style={{color: "#146697"}}>Reliable</span> {props.headingTwo}</h1>
          <p className="para" >{props.Para}</p>  
        </div>

        );
    }
    export default HeadingPara;