import React from 'react';
import  Col from "react-bootstrap/Col";
import  Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container"
import HeadingPara from "./heading";
import HeadparaContent from "../headingpara";
import Footer from "./footer";
import Product from './product';
import CardPaln from './card';
import CardContent from '../cardcontent';
import ImageTextbox from "./imagetextbox";
import fetch from "isomorphic-fetch";
import UnionBack from '../images/unionBack.svg';
import ImageContent from "../imagestext";
import Accoribg from "../images/accoribg.svg";
import Nodeacco from "../images/nodeacco.svg";


function CreateProduct(accessories) {
      return (
        <Product class="coloredCard " class2="padding-top" class3="padd-up-down-10"
          key={accessories.id}
          title={accessories.Name}
          text={accessories.Description.substring(0, 50)}
         Image= {`https://app.mmrmining.com${accessories.image1.url}`}
         targetpage={`/accessoriessingle/${accessories.id}`}

        />
      );
    }

function createCard(CardContent) {
  return (
    <CardPaln  class="plainCard" class2="padding-top white-text"
      key={CardContent.id}
      title={CardContent.title}
      button={CardContent.button}
      Image= {CardContent.image}
    />
  );
}



    

class AccessoriesPage extends React.Component {
  constructor(){
    super()
    this.state={
        accessories:[]
    }
  }
  
  componentWillMount(){
    fetch('https://app.mmrmining.com/accessories').then((response)=> {
      if(response.status >= 400){
        throw new Error("page not found ");
      }
      return response.json();
    }).then((accessories)=> {
      this.setState({accessories:accessories});
    })
    }
 render(){
    return (
   
      <div>
        <section style={{backgroundImage: `url(${Accoribg})`, backgroundSize:'cover'}} className="relative" >
      <Container className=" padd-up-down-per">
 <Row className="margin-zero">
 <Col sm="12" md="6" >
 <div className="padd-up-down">
<h3 className="yellow-text"> Drilling and Mining Accessories</h3>
<br/>
<h1 className="white"> Our Accessories boast long life and high fragmentation performance.</h1>
</div>
</Col>
<Col sm="12" md="6" >
<img src={Nodeacco} alt="" className="prdu-truck2"  />    
</Col>
</Row>
</Container>
</section>


  <Container>
  <br/>
  <br/>
  <br/>
  <center><h3 style={{color: "#146697"}}>Our Accessories</h3></center>
 
  <Row className=" margin-zero">
      {this.state.accessories.map(CreateProduct)}
      </Row>

     
</Container>
   <div >
      <section style={{backgroundImage: `url(${UnionBack})`, backgroundSize:'cover', width:'100%',}}  >
      <Container  className="padd-up-down">
      <div >
      <h2 className=" white-text papad">Uncompromised Quality Of <br/> Execution</h2><br/> 
      </div>
      <Row className="margin-zero">
      {CardContent.map(createCard)}
      </Row>
    </Container>
    </section>
    </div>
    
    <Container className="padd-up-down">
    <Row className="margin-zero">
          <Col sm="12" md="4">
          <HeadingPara class="padding-10" heading={HeadparaContent[0].heading} headingTwo={HeadparaContent[0].heading2}/>       
          </Col>
          <Col sm="12" md="4">
          <ImageTextbox   blue="Adopting Technologies in your mining requirements." image={ImageContent[7].image} heading={HeadparaContent[1].heading}  Para={HeadparaContent[7].Para}/><br/><br/>
        </Col>
        <Col sm="12" md="4">
        <ImageTextbox   blue="Spend smart for your next mining project" image={ImageContent[8].image} heading={HeadparaContent[2].heading} Para={HeadparaContent[8].Para}/><br/><br/>
        </Col>
        </Row>
        <br/>
        <br/>
    </Container>

<Footer/>
      </div>

);
}
}

export default AccessoriesPage;