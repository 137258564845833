
import ImageOne from './images/image-1.svg';
import ImageTwo from './images/image-2.svg';
import ImageThree from './images/image-3.svg';
import ImageFour from "./images/image-4.svg";
import ImageFive from "./images/Image5.svg";
import ImageSix from "./images/Image6.svg";
import ImageSeven from "./images/Image7.svg";
import Blog1 from "./images/blog1.svg";
import Blog2 from "./images/blog2.svg";

const ImageContent = [
    {
       title: "Lorem ipsum  amet, consectetur adipiscing da",
       button:"know-more",
       image:ImageThree
    },
    {
        title: "Lorem ipsum  amet, consectetur adipiscing da",
        button:"know-more",
        image:ImageOne
    },
    {
        title: "Lorem ipsum  amet, consectetur adipiscing da",
        button:"know-more",
        image:ImageTwo
    },
    {
        title: "Lorem ipsum  amet, consectetur adipiscing da",
        button:"know-more",
        image:ImageFour
    },
    {
        title: "Lorem ipsum  amet, consectetur adipiscing da",
        button:"know-more",
        image:ImageFive
    },
    {
        title: "Lorem ipsum  amet, consectetur adipiscing da",
        button:"know-more",
        image:ImageSix
    },
    {
        title: "Lorem ipsum  amet, consectetur adipiscing da",
        button:"know-more",
        image:ImageSeven
    },
    {
        image:Blog1
    },
    {
        image:Blog2
    }

  ];
  
  export default ImageContent;
  