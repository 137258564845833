import React from 'react';
import { Switch, Route,  BrowserRouter as Router  } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Header from "./header";
import Portfolio from "./Portfolio";
import ProductPage from "./productpage";
import Contact from "./contact";
import './App.css'; 
import ProductSingle from "./productsingle";
import AccessoriesPage from "./accessoriespage";
import AccessoriesSingle from "./accessoriessingle";
import ScrollToTop from "./ScrollToTop";





function App() {
  return (
    <React.Fragment>
  
    <Header/>
  
      <Router>
      <ScrollToTop/>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/About" component={About}/>
          <Route exact path="/Portfolio" component={Portfolio}/>
          <Route exact path="/productpage" component={ProductPage}/>
          <Route exact path="/accessoriespage" component={AccessoriesPage}/>
          <Route exact path="/productsingle/:postid" render={ props =>(
            <ProductSingle {...props}/>
          )}/>
           <Route exact path="/accessoriessingle/:postid" render={ props =>(
            <AccessoriesSingle {...props}/>
          )}/>
          <Route exact path="/contact" component={Contact}/>
        </Switch>
      </Router>
    </React.Fragment>
  );
}


export default App;
