import React from 'react';
import  Button  from 'react-bootstrap/Button';
import  Card from "react-bootstrap/Card";
import  Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";


function Product(props) {
    return (
<Col sm="12" md="3"   className={props.class3}>
<Card className={props.class}>
  <Card.Img variant="top" src={props.Image} className="go-up"/>
  <Card.Body>
    <Card.Title className="para-heading relative " style={{color: "#146697"}}>{props.title}</Card.Title>
    <Card.Text>
     <span className="para">{props.text}</span>
    </Card.Text>
 <Link to={props.targetpage}><Button>know more</Button></Link>
  </Card.Body>
</Card>
</Col>
);
}

export default Product;