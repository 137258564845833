import React from 'react';
import Container from "react-bootstrap/Container"
import  Col from "react-bootstrap/Col";
import  Row from "react-bootstrap/Row";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Footer from "./footer";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button';
import Background1 from "../images/background1.svg";
import {Link} from "react-router-dom";
import fetch from "isomorphic-fetch";

function ProDetails(AccessoriesDetails){
  return(

    <tr>
    <td>{AccessoriesDetails.Name}</td>
    <td>{AccessoriesDetails.details}</td>

    </tr>

  );
}
function ProSpeci(AccessoriesSpecification){
  return(

    <tr>
    <td>{AccessoriesSpecification.Name}</td>
    <td>{AccessoriesSpecification.Sepecification}</td>

    </tr>

  );
}


class AccessoriesSingle extends React.Component {
constructor(){
     super()
     this.state={
           SingleAccessories:[],
           ImageOne:'',
           ImageTwo:'',
           ImageThree:'',
           AccessoriesDetails:[],
           AccessoriesSpecification:[]
          
     } 
}
componentDidMount(){
      fetch(`https://app.mmrmining.com/accessories/${this.props.match.params.postid}`).then((response)=> {
            if(response.status >= 400){
              throw new Error("page not found ");
            }
            return response.json();
          }).then((accessory)=> {
            this.setState({SingleAccessories: accessory});
            this.setState({ImageOne: accessory.image1.url});
            this.setState({ImageTwo: accessory.image2.url});
            this.setState({ImageThree: accessory.image3.url});
            this.setState({AccessoriesDetails: accessory.accessories_details});
            this.setState({AccessoriesSpecification: accessory.accessories_specification});
          });

          }

render(){
  const {id, Name, Description} = this.state.SingleAccessories;

return (
   
      <div>
       <section style={{backgroundImage: `url(${Background1})`}} className="relative" >
<Container className="padd-up-down">
<Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row className="margin-zero">
    <Col sm={5} accessoryid={id}>
 
      <Tab.Content>
        <Tab.Pane eventKey="first">
        <img src={`https://app.mmrmining.com${this.state.ImageOne}`} width="100%" alt=""/>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
        <img src={`https://app.mmrmining.com${this.state.ImageTwo}`} width="100%" alt=""/>
        </Tab.Pane>
        <Tab.Pane eventKey="third">
        <img src={`https://app.mmrmining.com${this.state.ImageThree}`} width="100%" alt=""/>
        </Tab.Pane>
       
      </Tab.Content>
      <Nav variant="pills" className="flex-column">
      <Row className="margin-zero">
      <Col>
        <Nav.Item>
          <Nav.Link eventKey="first"><img src={`https://app.mmrmining.com${this.state.ImageOne}`} width="100%" alt=""/></Nav.Link>
        </Nav.Item>
        </Col>
        <Col>
        <Nav.Item>
          <Nav.Link eventKey="second"><img src={`https://app.mmrmining.com${this.state.ImageTwo}`} width="100%" alt=""/></Nav.Link>
        </Nav.Item>
        </Col>
        <Col>
        <Nav.Item>
          <Nav.Link eventKey="third"><img src={`https://app.mmrmining.com${this.state.ImageThree}`}  width="100%" alt=""/></Nav.Link>
        </Nav.Item>
        </Col>
        <Col>
      
        </Col>
        </Row>
      </Nav>
 
    </Col>
    <Col sm={6}>
    <Container>
         <h5 style={{color: "#146697"}} className="para-heading relative ">{Name}</h5>
         <p class="para">{Description}</p>
         <Link to="/Contact" ><Button >Contact-Us</Button></Link>
         </Container>
    </Col>
  </Row>
</Tab.Container>
</Container>
</section>

<Container>
<center>
<br/>
<h3 style={{color: "#146697"}}> Accessories Details</h3>
<br/>
<Col xs="12" md="6">
<Table responsive striped bordered hover> 
  <thead>
    <tr>
     
    <th>Products</th>
    <th>details</th>
     
    </tr>
  </thead>
  <tbody>
{this.state.AccessoriesDetails.map(ProDetails)}
</tbody>
</Table>
</Col>
</center>
</Container>
<Container>
<center>
<br/>
<h3 style={{color: "#146697"}} >Accessories Specifications</h3>
<br/>

<Col xs="12" md="6">
<Table striped bordered hover responsive>
  <thead>
    <tr>
     
    <th>Products</th>
    <th>details</th>
     
    </tr>
  </thead>
  <tbody>
{this.state.AccessoriesSpecification.map(ProSpeci)}
</tbody>
</Table>

</Col>
</center>
<br/>
<center>

<Link to="/Contact" ><Button >Contact-Us</Button></Link>
</center>
<br/>
</Container>
<Footer/>
      </div>

);
}
}


export default AccessoriesSingle;