import React from 'react';
import  Row from "react-bootstrap/Row";
import  Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Backhome from "../images/Backhome.svg";
import HomeBanner from "../images/homebanner.svg";
import Truck from "../images/1.svg";

function BannerHomeSmall(props) {
    return(

 
<section   style={{backgroundImage: `url(${Backhome})`, backgroundSize:'Cover'}}  className="first-backgound responsive relative"  alt="banner"  >  

<Container className="para-heading2 text-center">
  <Row className="margin-zero">
  <Col>
    <img src={Truck} alt="" className=" truck "/>
  </Col>
  <Col xs="6">
    <div style={{backgroundImage: `url(${HomeBanner})`, backgroundSize:'Cover'}} className="second-image responsive "  alt="banner-image"  > 
      <div className="paddig_7" >
        <h1 className="heading1">{props.heading}</h1><br/>
        <h1 className=" heading2" style={{ color:"#146697" }}>{props.colorHeading}</h1><br/>
       
     </div>
     </div>
   </Col> 

  </Row>
  </Container>
  </section>
  
  );
}
export default BannerHomeSmall;