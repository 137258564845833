import React from "react";


function ImageTextbox(props) {
    return(

        <div  className="relative">
        
           <img src={props.image} className=" box-shadow about-img" alt=""/>
      
           <div className="absolute text-content">
           <div className={props.class}>
          <span className="para-heading Font-heading relative">{props.heading}</span><br/><br/>
          <h5 className="Font-heading2 "><span style={{color: "#146697"}}>{props.blue}</span> {props.headingTwo}</h5> <br/><br/>
          <span className="Font-para" >{props.Para}</span>  <br/><br/>
        </div>
           </div>
      

        </div>
        );
    }
    export default ImageTextbox;