import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import BannerHome from "./Homebanner";
import BannerHomeSmall from "./Homebannersmall";
import BannerContent from "../bannercontent"; 
import SmBackhome from "../images/SmBackhome.svg"; 
import Twoimage from "./twoimages";
import Facebook from "../images/facebook.svg";
import Scroll from "../images/scroll.svg";
import Background1 from "../images/background1.svg";
import HeadingPara from "./heading";
import HeadparaContent from "../headingpara";
import ImageTextbox from "./imagetextbox";
import CardPaln from './card';
import CardContent from '../cardcontent';
import UnionBack from '../images/unionBack.svg';
import Product from './product';
import ImageContent from "../imagestext";
import AboutImg from "../images/aboutimg.svg";
import ProductBack from "../images/product-bg.svg";
import Carousel from "react-bootstrap/Carousel";
import Truck2 from "../images/2.svg";
import {Link} from "react-router-dom";



function createCard(CardContent) {
  return (
    <CardPaln  class="plainCard" class2="padding-top white-text text-center "
      key={CardContent.id}
      title={CardContent.title}
      button={CardContent.button}
      Image= {CardContent.image}
    />
  );
}
function CreateProduct(products) {
  return (
    <Product class="coloredCard " class2="padding-top" 
      key={products.id}
      title={products.name}
      text={products.description.substring(0, 50)}
     Image= {`https://app.mmrmining.com${products.image1.url}`}
     targetpage={`/productsingle/${products.id}`}

    />
  );
}
function CreateProductSmall(products) {
  return (
    <Carousel.Item>
    <Product class="coloredCard" class2="padding-top"
      key={products.id}
      title={products.name}
      text={products.description.substring(0, 50)}
     Image= {`https://app.mmrmining.com${products.image1.url}`}
     targetpage={`/productsingle/${products.id}`}
    />
      </Carousel.Item>
  );
}


class Home extends React.Component {
  constructor(){
    super()
    this.state={
      products:[]
    }
  }
  
  componentWillMount(){
    fetch('https://app.mmrmining.com/products').then((response)=> {
      if(response.status >= 400){
        throw new Error("page not found ");
      }
      return response.json();
    }).then((products)=> {
      this.setState({products:products});
    })
    }
 render(){
    return (

    <div >
       <Container fluid className="d-none d-sm-none d-md-block d-lg-block d-xl-block ">
      <BannerHome  
      imgurl={SmBackhome}  
      heading={BannerContent[2].heading} 
      colorHeading={BannerContent[2].colorHeading} 
              />
      
      <img src={Scroll} alt="" className="scroll absolute" />
      <img src={Facebook} alt="" className="facebook absolute" />
      </Container>
     <Container fluid className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
      <BannerHomeSmall  imgurl={SmBackhome}  heading={BannerContent[2].heading} colorHeading={BannerContent[2].colorHeading} />
      
      </Container>
   
      <section style={{backgroundImage: `url(${Background1})`, backgroundPosition:'right bottom'}} >
      
      <Row className="padding-50 margin-zero">
      <Col  className="d-none d-sm-none d-md-block d-lg-block d-xl-block " >
      <Twoimage/>
      </Col>
      <Col >
      <Container>
      <HeadingPara class="padding-15"  heading={HeadparaContent[0].heading} headingTwo={HeadparaContent[0].heading2} Para={HeadparaContent[0].Para} sm="12"/>
      </Container>
      </Col>
      <Col  style={{backgroundImage: `url(${Background1})`, backgroundSize:'cover'}} className="padding-20 d-block d-sm-block d-md-none d-lg-none d-xl-none " sm="12"> 
      <Twoimage/>
      </Col>
      </Row>
     
      </section>
     <section className="margin-top-10">
      <Container> 
      <Row className="margin-zero">
      <Col className="d-block d-sm-block d-md-none d-lg-none d-xl-none " xs="12">
        <h5 style={{color: "#146697"}} className="para-heading relative">Why Choose Us:</h5><br/>
        <h3>We <span style={{color: "#146697"}}>Design</span></h3>
        <h3>We <span style={{color: "#146697"}}>Manufacture </span></h3>
        <h3>We <span style={{color: "#146697"}}>Deliver</span></h3>
      </Col>
        <Col className="d-block d-sm-block d-md-none d-lg-none d-xl-none margin-top-10 " xs="12" > 
      <ImageTextbox  blue="We Plan." image={ImageContent[0].image} heading={HeadparaContent[1].heading} headingTwo={HeadparaContent[1].heading2} Para={HeadparaContent[1].Para}/><br/><br/>
      </Col>
      <Col className="d-none d-sm-none d-md-block d-lg-block d-xl-block ">
      <ImageTextbox  blue="We Plan." image={ImageContent[0].image} heading={HeadparaContent[1].heading} headingTwo={HeadparaContent[1].heading2} Para={HeadparaContent[1].Para}/><br/><br/>
      </Col>
      <Col className="d-none d-sm-none d-md-block d-lg-block d-xl-block ">
      <div className="padding-15">
      <h5 style={{color: "#146697"}} className="para-heading relative ">Why Choose Us:</h5>
        <h3 className="padding-top-10">We <span style={{color: "#146697"}}>Design</span></h3>
        <h3>We <span style={{color: "#146697"}}>Manufacture </span></h3>
        <h3>We <span style={{color: "#146697"}}>Deliver</span></h3>
        </div>
      </Col>
      </Row>
      </Container>
      <Container className="d-none d-sm-none d-md-block d-lg-block d-xl-block ">
      <Row className="margin-zero"> 
      <Col className="padding-top-not-20 padding-20-one" xs="12" md="6" sm="12">
      <ImageTextbox  blue="We Handover."image={ImageContent[1].image} heading={HeadparaContent[3].heading} headingTwo={HeadparaContent[2].heading2} Para={HeadparaContent[2].Para}/><br/><br/>
      </Col>
      <Col className="padding-top-not-20" xs="12" md="6" sm="12">
      <ImageTextbox    blue="We Execute." image={ImageContent[2].image} heading={HeadparaContent[2].heading} headingTwo={HeadparaContent[3].heading2} Para={HeadparaContent[3].Para}/><br/><br/>
      </Col>
      </Row>
      </Container>
      <Container className="d-block d-sm-block d-md-none d-lg-none d-xl-none " xs="12">
      <Row className="margin-zero">
      <Col className="padding-top-not-20 padding-20-one" xs="12" md="6" sm="12">
    
      <ImageTextbox  blue="We Execute." image={ImageContent[2].image} heading={HeadparaContent[2].heading} headingTwo={HeadparaContent[2].heading2} Para={HeadparaContent[3].Para}/><br/>
      </Col>
      <Col className="padding-top-not-20" xs="12" md="6" sm="12">
      <ImageTextbox   blue="We Handover." image={ImageContent[1].image} heading={HeadparaContent[3].heading} headingTwo={HeadparaContent[3].heading2} Para={HeadparaContent[2].Para}/><br/>
      </Col>
      
      </Row>
      </Container>
      </section>
      <Container className="d-none d-sm-none d-md-block d-lg-block d-xl-block padd-up-down" >
       <Row className="margin-zero">
         <Col xs="12" md="6" sm="12"  >
         <div  className="relative">
        
        <img src={AboutImg} className=" box-shadow about-img " alt=""/>
   
        <div className="absolute text-content back-blue" >
        <h5 className="white-text">GLOBAL PRESENCE<br/>
WITH <br/>CUSTOMER-FOCUSSED  <br/>EXPERIENCE</h5>
       </div>
      </div>
         </Col>
         <Col xs="12" md="6" sm="12">
         <Container>
         <h5 style={{color: "#146697"}} className="para-heading relative ">About Us:</h5>
         <p class="para">Drilling and mining is not a easy job. You need a trusted partner for your Daily Needs. MMR Mining Equipments understand mining & drilling Solutions thoroughly. Being in this industry for more than 30 years, Under the constant motivation and visionary guidance of our Chairman, ‘Mr. Raju’, we have reached the towering heights of success. His vast industry experience, in-depth knowledge and brilliant managerial skills have enabled us to consolidate our market position.</p>
         <Link to="/About"><Button>know-more </Button> </Link>
         </Container>
          </Col>
       </Row>
      </Container>
      <Container className="  padd-up-down d-block d-sm-block d-md-none d-lg-none d-xl-none" >
       <Row className="margin-zero">
  
         <Col xs="12" md="6" sm="12">
         <Container>
         <h5 style={{color: "#146697"}} className="para-heading relative ">About Us:</h5>
         <p class="para">Drilling and mining is not a easy job. You need a trusted partner for your Daily Needs. MMR Mining Equipments understand mining & drilling Solutions thoroughly. Being in this industry for more than 30 years, Under the constant motivation and visionary guidance of our Chairman, ‘Mr. Raju’, we have reached the towering heights of success. His vast industry experience, in-depth knowledge and brilliant managerial skills have enabled us to consolidate our market position.</p>
         <Link to="/About"><Button>know-more </Button> </Link>
         </Container>
          </Col>
          <Col xs="12" md="6" sm="12"  className="padding-20" >
         <div  className="relative">
        
        <img src={AboutImg} className=" box-shadow about-img " alt=""/>
   
        <div className="absolute text-content back-blue" >
        <h5 className="white-text">GLOBAL PRESENCE<br/>
WITH <br/>CUSTOMER-FOCUSSED  <br/>EXPERIENCE</h5>
       </div>
      </div>
         </Col>
       </Row>
      </Container>
      <section style={{backgroundImage: `url(${UnionBack})`, backgroundSize:'cover', width:'100%',}} >
      <Container className="padd-up-down">
      <div >
      <h2 className=" white-text papad">Uncompromised Quality Of <br/> Execution</h2><br/> 
      </div>
      <Row className="margin-zero">
      {CardContent.map(createCard)}
      </Row>
    </Container>
    </section>
    <section style={{backgroundImage: `url(${ProductBack})`, backgroundSize:'cover', width:'100%',}} >
    <Container   >
       <Row className="padd-up-down margin-zero" >
         <Col xs="12" md="6" sm="12">
         <h5 style={{color: "#146697"}} className="para-heading relative ">Products</h5>
         <br/>
         <h1 >We are a leading<br/><span style={{color: "#146697"}}>Drilling and Mining Equipments Manufacturers</span></h1>
         <p class="para">Our products are world-class and are completely customized to meet clients requirements. 
         we plan, execute and maintain all the products we deliver to our clients.</p>
          </Col>
          <Col xs="12" md="6" sm="12" className="text-center" >
          <img src={Truck2} alt="" className=" about-img "/>
         </Col>
       </Row>
      </Container>
      </section>
    <section className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
    
    <Container className="padd-up-down" >

      <Row className=" margin-zero">
      {this.state.products.slice(0, 4).map(CreateProduct)}
      </Row>
      
      </Container>
     
    </section>
    <section className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
  
      <Container className="padd-up-down" >
     <Row className=" margin-zero">
      <Carousel>
      {this.state.products.slice(0, 4).map(CreateProductSmall)}
   
      </Carousel>
      </Row>
      </Container>
     
    </section>

    <Footer/>
    </div>
  );
}
}

export default Home;