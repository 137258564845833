import React from 'react';
import  Card from 'react-bootstrap/Card';
import  Col from 'react-bootstrap/Col';

function CardPaln(props) {
    return (
<Col>
<Card className={props.class}>
<div className="image-div ">
  <Card.Img variant="top" src={props.Image}  />
  </div>
  <Card.Body className="text-center" >
    <Card.Title className={props.class2}>{props.title}</Card.Title>
    <span className="para-heading1  white-text">{props.button}</span>
  </Card.Body>
</Card>
</Col>
);
}

export default CardPaln;