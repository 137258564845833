import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import  Col from "react-bootstrap/Col";
import PortBack from "../images/portfolioback.svg";



function PortBanner(props) {
    return(

<section   style={{backgroundImage: `url(${PortBack})`, backgroundSize:'Cover'}}  className="first-backgound responsive relative about-banner relative"  alt="banner"  >
<Container fluid  className=" ">  

  <Row className="margin-zero">

   <Col md=" 12" xs="12"  >
      <div className=" padd-up-down port-heading" >
        <h1 className="headingport1">{props.heading}</h1><br/>
        <h1 className=" headingport2">{props.colorHeading}</h1><br/>
        <h1 className=" headingport3">{props.heading2}</h1>    
        </div>
    </Col>
   </Row>
  
</Container>
</section>



    );
}
export default PortBanner;