import React from "react";


function ProBar(props){
    return(
        <div  class="progressbar">
        <div id={props.idTypes}></div>
      </div>
    );
}
 export default ProBar;