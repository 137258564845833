import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import  Col from "react-bootstrap/Col";
import LogoTwo from "../images/logo2.svg";
import BackFooter from "../images/footer-back.svg";
import {Link} from "react-router-dom";
import ChatWidget from "@papercups-io/chat-widget";

function Footer() {
  const year = new Date().getFullYear();
  return (
    
    
    <section id="footer" style={{backgroundImage: `url(${BackFooter})`, backgroundSize:'cover', width:'100%',}}>
		<Container className="padd-up-down">
			<Row className="margin-zero  text-center text-xs-center text-sm-left text-md-left">
			<Col  xs="12" sm="12" md="3">
				<div className="five text-center"  >
				<img src={LogoTwo} alt=""  className="responsive"/>
					
				</div>
			<br/>
                  </Col>
				  <br/>
				<Col   >
				<div className="five">
				<h5 className="yellow-text">MMR MINING EQUIPMENTS</h5>
					<p>MMR mining equipments is a  leading manufacturer and exporter of world class drilling equipments and accessories.
                    </p>
					</div>
				</Col>
				<Col  >
				<div className="five">
					<h5 className="yellow-text">Quick links</h5>
					<ul className="list-unstyled quick-links">
				<li><Link  to="/" className="four" >Home</Link></li>
      <li><Link to="/Portfolio" className="four">Portfolio</Link></li>
      <li><Link  to="/productpage" className="four">Products</Link></li>
					</ul>
					</div>
				</Col>
				<Col  >
				<div className="five">
					<h5 className="yellow-text">Contact Us</h5>
				
						<div className="display-flex">
                        <i className="fa fa-map-marker"></i>
                       <p><span> Plot no 1 &2, Shastry nagar , Near Cherlapally busstop, Rampally Village,</span>Hyderabad, Telangana 501301</p>
                       
						</div>
					
                  
						<div className="display-flex">
                        <i className="fa fa-phone"></i>
                        <p>+91 9949188588</p>
                        </div>

                          <div className="display-flex">
                         <i className="fa fa-envelope"></i>
                         <p>mmr51011@gmail.com</p>
                          </div>
				
				
					 <ul className="footer-ul">
						<li className="list-inline-item"><a href="https://www.facebook.com/crawlerdrill"><i className="fa fa-facebook white-text"></i></a></li>
						<li className="list-inline-item"><a href="https://www.instagram.com/mmrminingequipments/"><i className="fa fa-instagram white-text"></i></a></li>
						<li className="list-inline-item"><a href="https://www.youtube.com/channel/UCn8hRKVXTohJgBbDpluy8tQ"><i className="fa fa-youtube white-text"></i></a></li>
			
					</ul>
					</div>
                  </Col>
                
     </Row>
     </Container>
	 <ChatWidget
        title="Welcome to MMR Mining"
        subtitle= "Ask us anything in the chat window below 😊"
        primaryColor="#1890ff"
        greeting=""
        newMessagePlaceholder="Start typing..."
        accountId="71d3e391-4c51-4319-931c-1f14f9b18dd4"
        baseUrl="https://app.papercups.io"
      />
	 <a href="https://api.whatsapp.com/send?phone=+917075768588&amp;text=Hi. I have a requirement. Let's discuss.." class="float" target="_blank"  rel="noopener noreferrer"  >
<i class="fa fa-whatsapp my-float"></i>
</a>

        <footer>
	
		<Row  className="margin-zero">
		<Col>
		<p>Copyright ⓒ {year}  MMR Mining Equipments Limited Designed and Developed by Palnesto</p>
		</Col>
	
		</Row>
		

           </footer>
 
     
      </section>
  );
}

export default Footer;