import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import  Col from "react-bootstrap/Col";
import AboutBack from "../images/about-back.svg"; 



function Banner(props) {
    return(

<section   style={{backgroundImage: `url(${AboutBack})`, backgroundSize:'Cover'}}  className="first-backgound responsive relative about-banner relative"  alt="banner"  >
<Container fluid  className=" ">  
  <Col xs="10" className="second-content">
  <Row className="margin-zero">
  <Col md="4" xs="6" >
      <img src={props.imgurl} className="second-image  responsive absolute"  alt={props.alt}   /> 
   </Col>
   <Col md="4" xs="6"  >
      <div className=" Banner-heading  " >
        <h1 className="heading-abt1">{props.heading}</h1><br/>
        <h1 className=" heading-abt2">{props.colorHeading}</h1><br/>
        <h1 className=" heading-abt1">{props.heading2}</h1>    
        </div>
    </Col>
   </Row>
  </Col>
</Container>
</section>


    );
}
export default Banner;