import React from "react";
import Footer from "./footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PortBanner from "./portofoliobannerimage";
import BannerContent from "../bannercontent";
import Backhome from "../images/Backhome.svg"; 
import PortLast from "../images/port-last.svg"; 
import ImageTextbox from "./imagetextbox";
import ImageContent from "../imagestext";
import HeadparaContent from "../headingpara";
import CardContent from '../cardcontent';
import CardPaln from './card';
import UnionBack from '../images/unionBack.svg';
import GalleryOne from '../images/gallery1.svg';
import GalleryTwo from '../images/gallery2.svg';
import GalleryThree from '../images/gallery3.svg';
import GalleryFour from '../images/gallery4.svg';
import GalleryFive from '../images/gallery5.svg';
import GallerySix from '../images/gallery6.svg';


function createCard(CardContent) {
  return (
    <CardPaln  class="plainCard" class2="padding-top white-text text-center "
      key={CardContent.id}
      title={CardContent.title}
      button={CardContent.button}
      Image= {CardContent.image}
    />
  );
}


function Portfolio() {
  return (
    <div >
    <PortBanner image={Backhome} alt=""    heading={BannerContent[1].heading} colorHeading={BannerContent[1].colorHeading} heading2={BannerContent[1].heading2}/>
     
  

 

     <section className="margin-top-10">
      <Container> 
      <Row className="margin-zero">
      <Col className="d-block d-sm-block d-md-none d-lg-none d-xl-none " xs="12">
        <h3  className="para-heading relative">We Provide Solutions For</h3><br/>
        
      </Col>
        <Col className="d-block d-sm-block d-md-none d-lg-none d-xl-none margin-top-10 " xs="12" > 
      <ImageTextbox  blue="Geographical exploration Products" image={ImageContent[4].image} heading={HeadparaContent[4].heading}  Para={HeadparaContent[4].Para}/><br/><br/><br/><br/><br/>
      </Col>
      <Col className="d-none d-sm-none d-md-block d-lg-block d-xl-block ">
      <ImageTextbox  blue="Geographical exploration Products" image={ImageContent[4].image} heading={HeadparaContent[4].heading}  Para={HeadparaContent[4].Para}/><br/><br/><br/><br/><br/>
      </Col>
      <Col className="d-none d-sm-none d-md-block d-lg-block d-xl-block ">
      <div className="padding-15">
      <h3  className="para-heading relative" style={{color: "#146697"}} >We Provide Solutions To</h3>
        
      <br/>
        </div>
      </Col>
      </Row>
      </Container>
      <Container className="d-none d-sm-none d-md-block d-lg-block d-xl-block ">
      <Row className="margin-zero">
      <Col className="padding-top-not-20 padding-20-one" xs="12" md="6" sm="12">
      <ImageTextbox  blue=" Exploration Accessories"image={ImageContent[6].image} heading={HeadparaContent[6].heading}  Para={HeadparaContent[6].Para}/><br/><br/><br/><br/><br/>
      </Col>
      <Col className="padding-top-not-20" xs="12" md="6" sm="12">
      <ImageTextbox    blue="Mining Products" image={ImageContent[5].image} heading={HeadparaContent[5].heading}  Para={HeadparaContent[5].Para}/><br/><br/><br/><br/><br/>
      </Col>
      </Row>
      </Container>
      <Container className="d-block d-sm-block d-md-none d-lg-none d-xl-none " xs="12">
      <Row className="margin-zero">
      <Col className="padding-top-not-20 padding-20-one" xs="12" md="6" sm="12">
    
      <ImageTextbox  blue="Mining Products" image={ImageContent[5].image} heading={HeadparaContent[5].heading}  Para={HeadparaContent[5].Para}/><br/><br/><br/><br/>
      </Col>
   
      <Col className="padding-top-not-20 small-height" xs="12" md="6" sm="12">
      <ImageTextbox   blue=" Exploration Accessories" image={ImageContent[6].image} heading={HeadparaContent[6].heading}  Para={HeadparaContent[6].Para}/><br/><br/><br/><br/>
      </Col>
      
      </Row>
      </Container>
      </section>

      <div className="padd-up-down port-abt">
      <section style={{backgroundImage: `url(${UnionBack})`, backgroundSize:'cover', width:'100%',}}  >
      <Container className="padd-up-down">
      <div >
      <h2 className=" white-text papad">Uncompromised Quality Of <br/> Execution</h2><br/> 
      </div>
      <Row className="margin-zero">
      {CardContent.map(createCard)}
      </Row>
    </Container>
    </section>
    </div>
    <Container className="padd-up-down">
    <Row className="margin-zero">
      <Col sm="12" md="6" className="d-none d-sm-none d-md-block d-lg-block d-xl-block  col">
      <br/>
      <img src={PortLast} className="responsive" alt=""/>
      </Col>
     
      <Col sm="12" md="6" >
    
      <div >
          <h3 className="para-heading relative"><span style={{color: "#146697"}}>Our Unique Selling Points:</span></h3>
          <br/>
        <ul className="port-list ">
        <li>Our wide range of products, offer customers a plethora of options to choose from. All our products are customizable and made with best quality measures in our facility.</li><br/>
        <li>Our R & D department strives continuously to develop new components and to improve the quality of our existing products as well, keeping in mind the key factors of user friendliness, long life and effectiveness.</li><br/>
        <li>The excellent infrastructure along with our network of agents enables us to cater to clients all over the country, even in the most remote areas and also ensures on-time delivery to all our clients.</li><br/>
        </ul>
        </div>
     
      </Col>
      <Col sm="12" md="6" className="d-block d-sm-block d-md-none d-lg-none d-xl-none ">
      <img src={PortLast} className="responsive" alt=""/>
      </Col>
      </Row>
    </Container>

     <Container>
     <center> <h3  className=" relative" style={{color: "#146697"}} >Our Gallery</h3></center>
     <br/>
      <Row className="margin-zero">
      <Col sm="12" md="4">
      <img src={GalleryOne} className="responsive one-size" alt=""/>
      </Col>
      <Col sm="12" md="4">
      <img src={GalleryTwo} className="responsive one-size" alt=""/>
       </Col>
       <Col sm="12" md="4">
       <img src={GalleryThree} className="responsive one-size" alt=""/>
       </Col>
        </Row>
         <Row className="margin-zero"> 
      <Col sm="12" md="4">
      <img src={GalleryFour} className="responsive one-size" alt=""/>
      </Col>
      <Col sm="12" md="4">
      <img src={GalleryFive} className="responsive one-size" alt=""/>
       </Col>
       <Col sm="12" md="4">
       <img src={GallerySix} className="responsive one-size" alt=""/>
      </Col>
     
         </Row>
     </Container>
  

    <Footer/>
    </div>
  );
}

export default  Portfolio;
