const BannerContent = [
    {
      name: "banner",
      heading: "   Design ",
      colorHeading:"Manufacture",
      heading2: "Deliver"
    },
    {
      heading: " Leading Manufacturer ",
      colorHeading: "  of Completely Customized ",
      heading2: "Drilling & Mining Equipments"
    },
    {
      heading: "  Are You Looking For ? ",
      colorHeading:"Blasthole Rig",
 
    }
  ];
  
  export default BannerContent;
  