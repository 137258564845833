const HeadparaContent = [
    {
      heading: "Your ",
      heading2:"Partner for Drilling, and Mining Equipments",
      Para:"MMR Mining equipments is your trusted partner for your Drilling,  and Mining endeavours. Leveraging on our multifaceted infrastructure facility; we manufacture and export vast array of Construction, Mining & pumping equipment."
    },
    {
      heading: "01",
      heading2: "That’s Where Ideas Take Shape ",
      Para:"In this step we Present detailed diagrams of the project. We customize client requirements to what works on ground. "
    },
    {
      heading: "02",
      heading2: "Key to My and My Clients Dreams  ",
      Para:"We make sure that when we say its done , it is done. From process timelines to milestones, we get it done."
      
    },
    {
      heading: "03",
      heading2: " Its what really matters",
      Para:"We only trust an professional for a job. A quality of a project is truly measured with the men and the tools."  
    },
    {
      heading: "",
      Para:"We transform impossibility to possibility. We are a Leading Manufacturer of wide range of drilling rigs. We are your partner for complete drilling solutions. Happy drilling !!" 
    }
    ,
    {
      heading: "",
      Para:"We address the world changing mining needs. Being a quality conscious company, we have been manufacturing, trader, supplying and exporting world class mining equipments." 
    }
    ,
    {
      heading: "",
      Para:"We don’t forget where we started. Being in accessories industry for more than 30 years, We deal with wide range of exploration accessories." 
    },
    {
      heading2:"Spend smart for your next mining project",
      Para:"All projects go through a lot of planning, process orientation, responsibilities, channelization."
    },
    {
      heading2:"Adopting Technologies in your mining requirements.",
      Para:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas risus, scelerisque eget tellus nulla"
    }
  ];
  
  export default HeadparaContent;
  